<template>
  <div class="sidepanel" :class="{ 'active': showSidepanel }">
    <div v-if="selectedInventoryVenueWine"
      style="display: flex; justify-content: space-between; flex-direction: column; height: 100%;">
      <div class="block-one">
        <b-row>
          <span class="font-14 color-150 w-700">{{ reduceTextFn(selectedInventoryVenueWine.venueWine.wine.winery.title, 20, true) }}</span>
        </b-row>
        <b-row>
          <span class="font-24 w-400 color-100 mb-xs">{{reduceTextFn( selectedInventoryVenueWine.venueWine.title, 25, true) }}</span>
        </b-row>
        <b-row class="mb-s">
          <b-col class="tags font-12 w-500 color-150">
            <span class="box">{{ selectedInventoryVenueWine.venueWine.bottleSize.amount }} l</span>
            <span class="box">{{ selectedInventoryVenueWine.venueWine.year }}</span>
            <span class="box">{{ selectedInventoryVenueWine.venueWine.wine.wineType.title }}</span>
            <span class="box">{{ selectedInventoryVenueWine.venueWine.wine.grapeVariety.title }}</span>
          </b-col>
        </b-row>
        <b-row class="color-100 font-14 w-700 mb-xs">
          <b-col class="rowSpaceBetween">
            {{ $t('general.ekNet') }}
            <TooltipButton v-if="!selectedInventoryVenueWine.canUpdatePurchasePrice"
              :tooltipText="$t('inventory.ekNetTooltip')" />
          </b-col>
          <b-col>
            {{ $t('general.vkGross') }}
          </b-col>
          <b-col>
            {{ $t('general.shelf') }}
          </b-col>
        </b-row>
        <b-row class="mb-s color-150">
          <b-col>
            <span class="input-euro right">
              <input min="0" type="number" :disabled="!selectedInventoryVenueWine.canUpdatePurchasePrice" v-model="sidePanelInventoryVenueWine.purchasePrice" >
            </span>
          </b-col>
          <b-col>
            <span class="input-euro right">
              <input min="0" type="number" v-model="sidePanelInventoryVenueWine.sellingPrice" >
            </span>
          </b-col>
          <b-col>
            <input v-model="sidePanelInventoryVenueWine.shelf">
          </b-col>
        </b-row>
      </div>
      <div class="block-two disable-dbl-tap-zoom">
        <div class="number-block">
          <b-row class="mb-s color-100">
            <span class="align-center font-40"
              :class="{ 'amount-not-set': selectedInventoryVenueWine.actualAmountFloat === null }">{{
                sidePanelInventoryVenueWine.actualAmountFloat == null ? 0 : sidePanelInventoryVenueWine.actualAmountFloat }} </span>
            <span class="align-center font-12 w-700">{{ $t('inventory.stock') }}</span>
          </b-row>
          <b-row class="number-pad font-18">
            <div class="number-row" v-for="row in numberPad" :key="row.join('')">
              <span class="number" v-for="n in row" :key="n">
                <b-button class="number" @click="addNumber(n)">{{ n }}</b-button>
              </span>
            </div>
            <div class="bottom-row">
              <span class="number">
                <b-button class="number" @click="addComma()">,</b-button>
              </span>
              <span class="number">
                <b-button class="number" @click="addNumber(0)">0</b-button>
              </span>
              <div @click="deleteNumber()" class="delete-btn align-center"><img
                  src="../../assets/img/arrow-v2-number-pad.svg" /></div>
            </div>
						<div class="bottom-row">
              <div class="duplicate align-center cursor-pointer">
                <div class="cursor-pointer" @click="markAsDuplicate">
                  <DuplicateIconChecked v-if="sidePanelInventoryVenueWine.duplicate === true" width="24" height="24" />
                  <DuplicateIcon v-else width="24" height="24" fill="rgba(73, 80, 87, 1)" />
									<span class="font-12 pl-xs">{{ $t('inventory.markAsDuplicate') }}</span>
                </div>	
              </div>
            </div>
          </b-row>

        </div>
      </div>
      <div>
        <b-row class="buttons">
          <b-col>

            <UpButton :onClick="previousRow" :buttonText="$t('general.buttons.back')" :disabled="upButtonDisabled" />
          </b-col>
          <b-col>

            <DownButton :onClick="nextRow" :buttonText="$t('inventory.next')" />
          </b-col>
        </b-row>
      </div>
    </div>
  </div>

</template>
<script>
import DataService from '@/services/StrapiService.js';
import DownButton from '../buttons/DownButton.vue';
import UpButton from '../buttons/UpButton.vue';
import TooltipButton from '../buttons/TooltipButton.vue';
import DuplicateIcon from '../icons/DuplicateIcon.vue';
import DuplicateIconChecked from '../icons/DuplicateIconChecked.vue';
import { checkForNullEmptyOrNegative, reduceText } from '@/utils/functions.js'
import Constants from '@/utils/constants.js'

export default {
  name: 'InventorySidepanel',
  props: {
    selectedInventoryVenueWine: {
      type: Object,
      default: null
    },
    selectedRowIndex: {
      type: Number,
    },
    tableLength: {
      type: Number
    },
    showSidepanel: {
      type: Boolean,
      default: false
    },
    currentPage: {
      type: Number
    },
  },
  components: {
    DownButton,
    UpButton,
    TooltipButton,
    DuplicateIcon,
    DuplicateIconChecked
  },
  mounted() {
  },
  data() {
    return {
      sidePanelInventoryVenueWine: {
        purchasePrice: 0,
        shelf: '',
        actualAmountFloat: null,
        targetAmountFloat: 0,
        duplicate: false,
        id: null,
        sellingPrice: 0
      },
      actualAmountFloatChanged: false,
      numberPad: [[1, 2, 3], [4, 5, 6], [7, 8, 9]],
      changeFromOutSide: false
    }
  },
  computed: {
    upButtonDisabled() {
      return this.selectedRowIndex === 0 && this.currentPage === 1;
    },
  },
  watch: {
    selectedInventoryVenueWine: {
      immediate: true,
      deep: true,
      handler() {
        this.resetInputs();
        if (!this.selectedInventoryVenueWine) {
          return;
        }
        this.sidePanelInventoryVenueWine = JSON.parse(JSON.stringify(this.selectedInventoryVenueWine));
      }
    }
  },
  created() {
  },
  methods: {
    markAsDuplicate() {
      this.sidePanelInventoryVenueWine.duplicate = !this.sidePanelInventoryVenueWine.duplicate;
      var alertobject = {
        text: this.$t("inventory.duplicateMarked"),
        variant: "info"
      };
      this.$store.commit("setAlertText", alertobject);
      this.nextRow();
    },
    emitUpdate() {
      this.sidePanelInventoryVenueWine.purchasePrice = checkForNullEmptyOrNegative(this.sidePanelInventoryVenueWine.purchasePrice);
      this.sidePanelInventoryVenueWine.sellingPrice = checkForNullEmptyOrNegative(this.sidePanelInventoryVenueWine.sellingPrice);
      this.$emit('updateFromSidePanel', this.sidePanelInventoryVenueWine);
    },

    nextRow() {
      this.actualAmountFloatChanged = false;
      
      this.saveInventoryVenueWine();

      if (this.selectedRowIndex === this.tableLength - 1) {
        this.$nextTick(() => {
          this.$emit('nextRow', this.sidePanelInventoryVenueWine, 0);
        });
      } else {
        this.$emit('nextRow', this.sidePanelInventoryVenueWine);
      }
    },
    previousRow() {
      this.actualAmountFloatChanged = false;
      this.$emit('previousRow');
      this.saveInventoryVenueWine();
    },
    resetInputs() {
      console.log('resetInputs');
      this.sidePanelInventoryVenueWine = {
        purchasePrice: 0,
        shelf: '',
        actualAmountFloat: null,
        targetAmountFloat: 0,
        duplicate: false,
        id: null,
        sellingPrice: 0
      }
    },
    async saveInventoryVenueWine() {
      let inventoryVenueWineState = Constants.inventory.CHECKED
      console.log('this.sidePanelInventoryVenueWine :>> ', this.sidePanelInventoryVenueWine);
      /* Sidepanel-Verhalten: wenn nur auf nächstes geklickt: Anzahl 0 setzen und nicht auf checked setzen */
      if (this.sidePanelInventoryVenueWine.actualAmountFloat === null && !this.sidePanelInventoryVenueWine.duplicate  ) {
        inventoryVenueWineState = Constants.inventory.NONE
        this.sidePanelInventoryVenueWine.actualAmountFloat = null;
      }

      this.sidePanelInventoryVenueWine.inventoryVenueWineState = inventoryVenueWineState;
      this.sidePanelInventoryVenueWine.targetAmountFloat = Number(this.sidePanelInventoryVenueWine.targetAmountFloat);
      console.log('this.sidePanelInventoryVenueWine 2 :>> ', this.sidePanelInventoryVenueWine);
      DataService.updateInventoryVenueWine(this.selectedInventoryVenueWine.id, this.sidePanelInventoryVenueWine).then(() => {
      }).catch((err) => {
        console.log('err :>> ', err);
      })
    },
    addNumber(n) {     
      this.sidePanelInventoryVenueWine.actualAmountFloat = this.sidePanelInventoryVenueWine.actualAmountFloat === null ? 0 : this.sidePanelInventoryVenueWine.actualAmountFloat;
      this.actualAmountFloatChanged = true;
      if (this.sidePanelInventoryVenueWine.actualAmountFloat === 0 || this.sidePanelInventoryVenueWine.actualAmountFloat === null || this.sidePanelInventoryVenueWine.actualAmountFloat === '0') {
        this.sidePanelInventoryVenueWine.actualAmountFloat = n.toString();
      } else {
        this.sidePanelInventoryVenueWine.actualAmountFloat += n.toString();
      }
      this.emitUpdate();
    },
		addComma() {
			this.sidePanelInventoryVenueWine.actualAmountFloat = this.sidePanelInventoryVenueWine.actualAmountFloat === null ? 0 : this.sidePanelInventoryVenueWine.actualAmountFloat;
			this.actualAmountFloatChanged = true;
			const hasComma = this.sidePanelInventoryVenueWine.actualAmountFloat.includes('.');
			if (this.sidePanelInventoryVenueWine.actualAmountFloat === 0 || this.sidePanelInventoryVenueWine.actualAmountFloat === null || this.sidePanelInventoryVenueWine.actualAmountFloat === '0') {
				this.sidePanelInventoryVenueWine.actualAmountFloat = '0.';
			} else if (!hasComma)  {
				this.sidePanelInventoryVenueWine.actualAmountFloat += '.';
			}
			this.emitUpdate();
		},
    deleteNumber() {
      
      this.actualAmountFloatChanged = true;
      if (this.sidePanelInventoryVenueWine.actualAmountFloat === null) {
        return
      }
      this.sidePanelInventoryVenueWine.actualAmountFloat = this.sidePanelInventoryVenueWine.actualAmountFloat.toString();
      if (this.sidePanelInventoryVenueWine.actualAmountFloat.length === 1) {
        this.sidePanelInventoryVenueWine.actualAmountFloat = null;
      } else {
        this.sidePanelInventoryVenueWine.actualAmountFloat = this.sidePanelInventoryVenueWine.actualAmountFloat.slice(0, -1);
      }
      this.emitUpdate();
    },
    reduceTextFn(text, length, addDots) {
      return reduceText(text, length, addDots);
    }

  }
}
</script>
<style scoped>
div.sidepanel {
  display: flex;
  flex-grow: 0;
  flex-shrink: 0;
  flex-basis: 389px;
  width: 389px;
  top: 0;
  background-color: var(--color-gray-400);
  border-left: 1px solid var(--color-gray-300);
  padding: var(--margin-xs) var(--margin-m) var(--margin-m) var(--margin-m);
  flex-direction: column;
  transition: margin 0.8s;
  margin-right: -389px;
  justify-content: space-between;
}

div.sidepanel.active {
  right: 0;
  transform: translateX(0);
  margin-right: 0;
}

div.block-two {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  flex-grow: 1;
}

div.calculate {
  flex-grow: 1;
}

span.box {
  display: inline;
  padding: 4px 8px;
}

div.tags {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 10px;
}

button.number {
  display: flex;
  padding: var(--margin-s);
  justify-content: center;
  align-items: center;
  height: 52px;
  width: 52px;
  background-color: var(--color-gray-300);
  border-radius: 6px;
  border: none;
}

.duplicate {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 44px;
}

div.calculate {
  flex-direction: column;
}

div.number-row {
  display: flex;
  justify-content: center;
  gap: 8px;
  margin-bottom: 8px
}

.number-pad {
  width: 187px;
}

.bottom-row {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  gap: 8px;
}

.bottom-row .delete-btn {
  height: 52px;
  width: 52px;
}

.buttons * {
  font-weight: 700;
  line-height: normal;
  text-transform: uppercase;
}

.amount-not-set {
  opacity: 0.4;
}

.input-euro {
     position: relative;
 }
 .input-euro.left input {
     padding-left:18px;
 }
 .input-euro.right input {
     padding-right:18px;
     text-align:start; 
 }

 .input-euro:before {
     position: absolute;
     top: 0;
     content:"€";
 }
 .input-euro.left:before {
     left: 5px;
 }
 .input-euro.right:before {
     right: 5px;
 }

</style>