<template>
	<b-modal size="xl" v-model="showReportDetail" id="reportDetailModal" :title="reportTitle" @ok="printReport()" ok-only
		:ok-title="$t('reports.reportDetailModal.printReport')">
		<div class="spacer m" />
		<h2 v-if="formatDate(reportPeriod.from) !== 'Invalid Date'">
			{{ $t('general.period') }}: {{ formatDate(reportPeriod.from) }} - {{ formatDate(reportPeriod.to) }}
		</h2>

		<div class="spacer m" />

		<div v-if="loading">
			<LoadingAnimation />
		</div>

		<div
			v-if="reportData.attributes && reportData.attributes.inventoryVenueWines && reportData.attributes.inventoryVenueWines.data.length > 0">
			<b-row>
				<b-col v-for="category in getCategories" :key="category.id">
					<InventoryReportTotalAmountColumn :reportData="reportData" :category="category" />
				</b-col>
			</b-row>

			<div class="spacer m" />

			<b-table :sort-by.sync="sortBy" @sort-changed="sortingChanged" ref="emptySoonWineList"
				:items="reportData.attributes.inventoryVenueWines.data" :fields="fields" sticky-header
				class="reportDetailsTable">
				<template #cell(difference)="data">
					{{ data.item.attributes.actualAmountFloat - data.item.attributes.targetAmountFloat }}
				</template>
				<template #cell(actualValue)="data">
					{{ (data.item.attributes.actualAmountFloat * data.item.attributes.purchasePrice).toLocaleString('de-DE'
						, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} {{ getCurrency }}
				</template>
				<template #cell(targetValue)="data">
					{{ (data.item.attributes.targetAmountFloat * data.item.attributes.purchasePrice).toLocaleString('de-DE'
						, { minimumFractionDigits: 2, maximumFractionDigits: 2 }) }} {{ getCurrency }}
				</template>

			</b-table>
		</div>
		<div v-else-if="!loading">
			<p>{{ this.$t('general.noData') }}</p>
		</div>
	</b-modal>
</template>

<script>
import { mapGetters } from 'vuex'
import DateTimeService from '@/services/DateTimeService'
import { getVenueCurrency } from '@/utils/functions'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import DataService from '@/services/StrapiService'
import InventoryReportTotalAmountColumn from './components/InventoryReportTotalAmountColumn.vue'

export default {
	name: 'InventoryReportModal',
	data: function () {
		return {
			loading: true,
			showReportDetail: false,
			reportData: {},
			reportType: "",
			reportPeriod: {},
			reportTitle: '',
			sortBy: '',
		}
	},
	created() { },
	methods: {
		async showModal(report) {
			this.loading = true
			this.reportData = {}
			this.reportType = ""
			this.showReportDetail = true
			this.reportPeriod = {
				from: report.attributes.dateFrom,
				to: report.attributes.dateTo
			}
			this.reportTitle = report.attributes.title

			await DataService.getInventory(report.id).then((response) => {
				this.reportData = response['data'].data
				this.reportType = report.attributes.type
				this.loading = false
			}).catch((err) => {
				console.log(err)
				this.loading = false
			})
		},
		formatDate(date, time) {
			var newDate = new Date(date)
			var parsedDatetime = DateTimeService.dateToFormattedstring(newDate, time, this.$i18n.locale)
			return parsedDatetime
		},
		printReport() {
			window.print()
		},
		sortingChanged(ctx) {
			this.sortBy = ctx.sortBy
			this.refreshTable()
		},
		refreshTable() {
			this.$root.$emit('bv::table::refresh', 'organizations-table')
		},
	},
	computed: {
		fields() {
			var fields = [
				{
					key: 'attributes.venue_wine.data.attributes.title',
					label: this.$t('general.bottles'),
					sortable: true
				},
				{
					key: 'attributes.venue_wine.data.attributes.wine.data.attributes.category.data.attributes.title',
					label: this.$t('general.category'),
					sortable: true
				},
				{
					key: 'attributes.venue_wine.data.attributes.year',
					label: this.$t('general.year'),
					sortable: true
				},
				{
					key: 'attributes.venue_wine.data.attributes.bottleSize.data.attributes.amount',
					label: this.$t('general.bottleSize'),
					sortable: true
				},
				{
					key: 'attributes.actualAmountFloat',
					label: this.$t('inventory.actual') + ' ' + this.$t('general.amount'),
					sortable: true
				},
				{
					key: 'attributes.targetAmountFloat',
					label: this.$t('inventory.target') + ' ' + this.$t('general.amount'),
					sortable: true
				},
				{
					key: 'difference',
					label: this.$t('inventory.difference'),
					sortable: true
				},
				{
					key: 'actualValue',
					label: this.$t('inventory.actual') + ' ' + this.$t('inventory.valueOfGoods'),
					sortable: true
				},
				{
					key: 'targetValue',
					label: this.$t('inventory.target') + ' ' + this.$t('inventory.valueOfGoods'),
					sortable: true
				}

			]

			return fields
		},
		getCurrency() {
			return getVenueCurrency(this.getVenue)
		},
		...mapGetters(['getVenue', 'getCategories'])
	},
	components: { LoadingAnimation, InventoryReportTotalAmountColumn }
}
</script>
