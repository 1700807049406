<template>
	<div v-if="hasInventoryVenueWinesOfCategory">
		<b-row>
			<b-col>
				<span class="font-14 w-700">{{ this.category.attributes.title }}:</span>
			</b-col>
		</b-row>

		<div class="spacer s" />

		<b-row>
			<b-col>
				{{ this.$t('general.total') + ' ' + this.$t('inventory.actual') + ' ' + this.$t('inventory.valueOfGoods') + ': ' }}
				<b>{{ totalActualAmountFloat.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' +
					getCurrency }} </b>
			</b-col>
		</b-row>
		<b-row>
			<b-col>
				{{ this.$t('general.total') + ' ' + this.$t('inventory.target') + ' ' + this.$t('inventory.valueOfGoods') + ': ' }} <b>
					{{ totalTargetAmountFloat.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + ' ' +
						getCurrency }} </b>
			</b-col>
		</b-row>

		<div class="spacer s" />

		<b-row>
			<h3>{{ $t('inventory.amountsPerCountry') }}:</h3>
			<b-col>
				<div v-for="(value, key) in amountsPerCountryFiltered" v-bind:key="key">
					{{ $t('countries.' + key) }}: <b>{{ value.toLocaleString('de-DE', {
						minimumFractionDigits: 2,
						maximumFractionDigits: 2
					}) }} {{ getCurrency }}</b>
				</div>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getVenueCurrency } from '@/utils/functions'
import Constants from '@/utils/constants'

export default {
	name: 'InventoryReportTotalAmountColumn',
	props: {
		reportData: {},
		category: {},
	},
	data() {
		return {
			amountsPerCountry: {},
			totalActualAmountFloat: 0,
			totalTargetAmountFloat: 0,
		};
	},
	methods: {
		calculateTotalAmounts() {
			this.totalActualAmountFloat = 0
			this.totalTargetAmountFloat = 0
			this.amountsPerCountry = {}

			if (!this.reportData || !this.reportData.attributes) {
				return;
			}

			this.getInventoryVenueWinesOfCategory.forEach(element => {
				this.totalActualAmountFloat += element.attributes.actualAmountFloat * element.attributes.purchasePrice
				this.totalTargetAmountFloat += element.attributes.targetAmountFloat * element.attributes.purchasePrice

				if (element.attributes.venue_wine.data.attributes.wine.data.attributes.land) {
					if (this.amountsPerCountry[element.attributes.venue_wine.data.attributes.wine.data.attributes.land.data.attributes.localeKey]) {
						this.amountsPerCountry[element.attributes.venue_wine.data.attributes.wine.data.attributes.land.data.attributes.localeKey] += element.attributes.actualAmountFloat * element.attributes.purchasePrice
					} else {
						this.amountsPerCountry[element.attributes.venue_wine.data.attributes.wine.data.attributes.land.data.attributes.localeKey] = element.attributes.actualAmountFloat * element.attributes.purchasePrice
					}
				}
			});
		},
	},
	computed: {
		/**
		 * Filters InventoryVenueWines by category
		 */
		getInventoryVenueWinesOfCategory() {
			return this.reportData.attributes.inventoryVenueWines.data.filter(inventoryVenueWine => {
				const inventoryVenueWineCategory = inventoryVenueWine.attributes.venue_wine.data.attributes.wine.data.attributes.category

				const isColWineCategory = this.category.id === Constants.CATEGORY_ID_WINE
				const inventoryVenueWineHasNoCategory = !inventoryVenueWineCategory || !inventoryVenueWineCategory.data || !inventoryVenueWineCategory.data.id

				if (isColWineCategory && inventoryVenueWineHasNoCategory) {
					return true
				}

				if (inventoryVenueWineHasNoCategory) {
					return false
				}

				const inventoryVenueWineBelongsToCategory = inventoryVenueWineCategory.data.id === this.category.id

				return inventoryVenueWineBelongsToCategory
			})
		},
		hasInventoryVenueWinesOfCategory() {
			return this.getInventoryVenueWinesOfCategory.length > 0
		},
		getCurrency() {
			return getVenueCurrency(this.getVenue)
		},
		/**
		 * Filters out countries with no amounts and sorts the remaining ones by amount in descending order
		 */
		amountsPerCountryFiltered() {
			return Object.fromEntries(Object.entries(this.amountsPerCountry).filter(([, v]) => v != null && v != 0).sort((a, b) => b[1] - a[1]));
		},
		...mapGetters(['getVenue'])
	},
	mounted() {
		this.calculateTotalAmounts()
	}
};
</script>

<style scoped></style>