export default {
	SHOULD_DELETE: "shouldDelete",
  inventory:{
    NONE: "none",
    CHECKED: "checked",
    TRANSFERRED: "transferred",
    TRANSFERRED_UNCHECKED: "transferred-unchecked",
  },
	CATEGORY_ID_WINE: 3,
	CATEGORY_ID_BEER: 2,
	CATEGORY_ID_SPIRIT: 4,
	CATEGORY_ID_NON_ALCOHOL: 1,
}