<template>
  <div class="mb-m mt-m">
    <createWinesModal ref="createWinesModal" @addedNewVenueWines="handleAddedNewVenueWines" />

    <div v-if="loading">
      <LoadingAnimation />
    </div>
    <div v-if="!loading" class="viewContainer inventory">
      <div>
        <SubmitInventoryModal :inventoryVenueWineState="inventoryVenueWineState" :inventoryId="inventory.id" @inventorySubmitted="handleInventorySubmitted" />
        <SyncInventoryModal :inventoryVenueWineState="inventoryVenueWineState" :inventoryId="inventory.id" @inventorySynced="handleInventorySynced" />
      </div>
      <div class="head mb-m">
       
        <Statuscards :inventoryVenueWineState="inventoryVenueWineState"/>
      
        <Button v-if="isInventoryOnly" class="w-m" :onClick="handleSubmit" :buttonText="$t('inventory.submit')"
          :disabled="inventory.inventoryState === 'uploaded' || inventory.inventoryState === 'finished'"
          :primary="true">
          <template v-slot:svg>
            <SubmitIcon />
          </template>
        </Button>
        <Button v-if="!isInventoryOnly && inventory.inventoryState !== 'finished'" class="w-m"
          :onClick="handleSyncInventory" :buttonText="$t('inventory.finish')" :disabled="disableSyncButton"
          :primary="true">
        </Button>

        <Button v-if="!isInventoryOnly && inventory.inventoryState === 'finished'" class="w-m" :onClick="createReport"
          :buttonText="$t('inventory.createReport')" :primary="false">
          <template v-slot:svg>
            <DownloadIcon />
          </template>
        </Button>



      </div>
      
      <div >
        <Performance class="mb-l" :inventoryVenueWineState="inventoryVenueWineState" :showWinesToHide="!isInventoryOnly"/>
        <div class="products mb-l">
          <h3 class="font-18 font-700 color-100">{{ $t('inventory.allProducts') }}</h3>
        
          <InventoryStatsRow :label="$t('inventory.unchecked')" :value="inventoryVenueWineState.totalWines - inventoryVenueWineState.checkedWines.total" :clickFunction="() => showInventoryWines('uncheckedWines', 'inventory.unchecked')" />
          <InventoryStatsRow :label="$t('inventory.checked')" :value="inventoryVenueWineState.checkedWines.total" :clickFunction="() => showInventoryWines('checkedWines', 'inventory.checked')" />

          <button :disabled="inventory.inventoryState === 'finished'" @click="addWine()" class="iconButton add">{{
            $t('inventory.addProduct')
          }}</button>


        </div>
        <div class="performance-optimization mb-l">
          <h3 class="font-18 font-700 color-100">{{ $t('inventory.performanceOptimization') }}</h3>
          <p class="color-150 font-14 mb-s">{{ $t('inventory.performanceHint') }}</p>

            <InventoryStatsRow :label="$t('inventory.divergentStock')" :value="inventoryVenueWineState.divergentStock" :clickFunction="() =>showInventoryWines('divergentStock', 'inventory.divergentStock')" >
              <div class="mr-s d-inline"><DivergentIcon /></div>
            </InventoryStatsRow>



            <InventoryStatsRow :label="$t('inventory.incomplete')"
              :value="inventoryVenueWineState.checkedWines.incomplete" :clickFunction="() => showInventoryWines('incompleteWines', 'inventory.incomplete')">
              <div class="mr-s d-inline"><IncompleteIcon /></div>
            </InventoryStatsRow>

 
            <InventoryStatsRow :label="$t('inventory.duplicates')" :value="inventoryVenueWineState.duplicateWines" :clickFunction="() => showInventoryWines('duplicateWines', 'inventory.duplicates')">
              <div class="mr-s d-inline"><DuplicateIcon width="16" height="16" /></div>
            </InventoryStatsRow>
        </div>
      </div>
    </div>
    <InventoryReportModal ref="inventoryReportModal" />
  </div>
  
</template>
<script>
import Button from '@/components/buttons/Button.vue';
import SubmitIcon from '@/components/icons/SubmitIcon.vue';
import DataService from '@/services/StrapiService.js';
import DataMappingService from '@/services/DataMappingService.js';
import SubmitInventoryModal from '@/components/modals/inventory/SubmitInventoryModal.vue';
import DivergentIcon from '@/components/icons/DivergentIcon.vue';
import { filterInventoryVenueWines } from '@/utils/functions';
import { mapGetters } from 'vuex';
import SyncInventoryModal from '@/components/modals/inventory/SyncInventoryModal.vue';
import LoadingAnimation from '@/components/LoadingAnimation.vue';
import DownloadIcon from '@/components/icons/DownloadIcon.vue';
import Performance from '@/components/inventory/Performance.vue';
import { getVenueCurrency } from '@/utils/functions'
import InventoryStatsRow from '@/components/inventory/InventoryStatsRow.vue';
import CreateWinesModal from '@/components/modals/addWine/CreateWinesModal.vue';
import DuplicateIcon from '@/components/icons/DuplicateIcon.vue';
import IncompleteIcon from '@/components/icons/IncompleteIcon.vue';
import Statuscards from '@/components/cards/StatusCards.vue';
import { processVenueWineIds } from '@/utils/functions';
import InventoryReportModal from '@/components/modals/inventory/InventoryReportModal.vue';


export default {
  name: 'Inventory',
  props: {
    id: {
      type: Number,
      default: 0
    }
  },
  components: {
    Button,
    SubmitIcon,
    SubmitInventoryModal,
    SyncInventoryModal,
    LoadingAnimation,
    DownloadIcon,
    Performance,
    InventoryStatsRow,
    CreateWinesModal,
    DivergentIcon,
    DuplicateIcon,
    IncompleteIcon,
    Statuscards,
    InventoryReportModal
  },
  data() {
    return {
      inventory: {
        id: 0,
        name: '',
        inventoryVenueWines: []
      },
      inventoryVenueWineState: {
        checkedWines: {
          total: 0,
          incomplete: 0,
          complete: 0,
        },
        totalWines: 0,
        actualAmountFloatTotal: 0,
        targetAmountFloatTotal: 0,
        totalValue: 0,
        targetValue: 0,
        actualBottleAmountFloat: 0,
        targetBottleAmountFloat: 0,
        divergentStock: 0,
        selectedWineObject: {},
        winesToHide: [],
        duplicateWines: 0
      },
      loading: false,
      performanceWidth: 0

    }
  },
  created() {
    this.loadInventory(this.$route.params.id);
  },
  mounted() {
   
  },

  methods: {
    addWine() {
      this.$refs.createWinesModal.showModal();
    },
    handleAddedNewVenueWines(venueWineIds) {
      processVenueWineIds(
        venueWineIds,
        this.inventory.id,
        this.loadInventory,
        this.$route,
        this.$store,
        this.$t
      );
      this.loadInventory(this.$route.params.id);
    },
    createReport() {
      const inventoryReport = {
        id: this.inventory.id,
        attributes:{
        title: this.inventory.name,
        dateFrom: this.inventory.startDate,
        dateTo: this.inventory.transferDate,
        type: 'inventory-'+this.inventory.inventoryType,
        venue: this.inventory.venue,
      }}
      this.$refs.inventoryReportModal.showModal(inventoryReport);
    },
    handleInventorySynced() {
      this.loadInventory(this.$route.params.id);
    },
    handleInventorySubmitted() {
      this.loadInventory(this.$route.params.id);
    },
    showInventoryWines(filterString, titleKey) {
      this.$router.push({ name: 'InventoryWines', params: {id: this.inventory.id, inventory: this.inventory, filterString: filterString,titleKey: titleKey } });
    },
    getInventoryStats(inventoryVenueWines) {

      const filteredWines = filterInventoryVenueWines(inventoryVenueWines);

      this.inventoryVenueWineState.checkedWines.total = filteredWines.checkedWines.length;
      this.inventoryVenueWineState.checkedWines.incomplete = filteredWines.incompleteWines.length;
      this.inventoryVenueWineState.checkedWines.complete = filteredWines.completeWines.length;
      this.inventoryVenueWineState.totalWines = inventoryVenueWines.length;
      this.inventoryVenueWineState.divergentStock = filteredWines.divergentStock.length;
      this.inventoryVenueWineState.duplicateWines = filteredWines.duplicateWines.length;

      this.inventoryVenueWineState.totalValue = filteredWines.checkedWines.reduce((acc, wine) => acc + wine.actualAmountFloat * wine.purchasePrice, 0);
      this.inventoryVenueWineState.targetValue = inventoryVenueWines.reduce((acc, wine) => acc + wine.targetAmountFloat * wine.purchasePrice, 0);
      this.inventoryVenueWineState.actualBottleAmountFloat = filteredWines.checkedWines.reduce((acc, wine) => acc + wine.actualAmountFloat, 0);
      this.inventoryVenueWineState.targetBottleAmountFloat = inventoryVenueWines.reduce((acc, wine) => acc + wine.targetAmountFloat, 0);
      this.inventoryVenueWineState.winesToHide = inventoryVenueWines.filter(wine => wine.inventoryVenueWineState === 'unchecked' || wine.actualAmountFloat == 0 || wine.actualAmountFloat == null);

    },

    loadInventory(inventoryId) {
      this.loading = true;
      DataService.getInventory(inventoryId)
        .then(response => {
          this.inventory = DataMappingService.mapGetInventoryResponse(response.data.data);
          this.$store.commit('setTopBarHeadLine', {
            texts: [
              {
                text: this.$t('inventory.inventories'),
                link: { name: 'Inventories' },
              },

              { text: this.inventory.name }],
            inventorystate: this.inventory.inventoryState
          });
          
          this.getInventoryStats(this.inventory.inventoryVenueWines)
        })
        .catch(e => {
          console.log(e);
        }).finally(() => {
          this.loading = false;
        });
    },
    handleSubmit() {
      this.$bvModal.show('submit-inventory-modal')
    },
    handleSyncInventory() {
      this.$bvModal.show('sync-inventory-modal')
    }
  },
  computed: {
    ...mapGetters(['isInventoryOnly', 'getVenue']),
    getCurrency() {
      return getVenueCurrency(this.getVenue)
    },
    disableSyncButton() {
      return this.inventory.inventoryState !== 'uploaded' && (this.inventory.inventory_users !== null && this.inventory.inventory_users.length > 0)
    },
  }
}
</script>
<style scoped>
.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

}

.left {
  display: flex;
  gap: var(--margin-s);
  align-items: center;
}

.cards {
  display: flex;
  flex-wrap: wrap;
  gap: var(--margin-s);
}

</style>