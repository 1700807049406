<template>
  <div style="height: 100%; position:relative;">
  <b-row  class="filterRow">
    <b-col @click="sendMessageToParent" :class="clickAllowed? 'clickableElement' : ''" >
      <h2>
        <i18n path="dashboard.topRevenueWidget.title" >
          <template v-slot:amount>
            {{ chartLimit }}
          </template>
        </i18n>
      </h2>
    </b-col>
    <b-col class="getAllContent">
      <b-button-group class="toggleButtons wineViewFilter">
          <b-button @click="refreshChart('revenue')" :class="revenueChartFilter=='revenue'? 'active':''">{{ $t('dashboard.topRevenueWidget.sales')}}</b-button>
          <b-button @click="refreshChart('bottles')" :class="revenueChartFilter=='bottles'? 'active':''">{{ $t('dashboard.topRevenueWidget.soldBottles')}}</b-button>
          <b-button @click="refreshChart('glasses')" :class="revenueChartFilter=='glasses'? 'active':''">{{ $t('winelist.filterOpenWines')}}</b-button>

      </b-button-group>  
    </b-col>
  </b-row>
  <div class="spacer xl"></div>
  <b-row  @click="sendMessageToParent" :class="clickAllowed? 'clickableElement' : ''">  
    <b-col >
      <div class="chartOuterContainer">
        <BarChart class="chartContainer" v-if="!loading && chartTypeData.length > 0" :backgroundColors="chartBackgrounds" :wineChartData="chartTypeData" :scalesObject="scalesObject" :currency="shownCurrency" :type="topType" :axis="'y'" />
        
      </div>
      <LoadingAnimation v-if="loading" />
        
    </b-col>
  </b-row>
  <div class="emptyResutsWidget" v-if="chartTypeData.length <= 0">
    <i18n class="emptyState" path="dashboard.topRevenueWidget.noWines" >
      <template v-slot:amount>
        {{ chartLimit }}
      </template>
    </i18n>
  </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import BarChart from '@/components/charts/BarChart.vue'
import DataService from '@/services/StrapiService'
import LoadingAnimation from '@/components/LoadingAnimation.vue'
import { getVenueCurrency } from '@/utils/functions'

export default {
  name: "RevenueDetailWidget",
  components: {
   BarChart,
   LoadingAnimation
  },
  data() {
    return {
      revenueChartFilter: 'revenue',
      responseChartData: {},
      topType: 'top',
      chartTypeData: {},
      loading: true,
      shownCurrency: '',
      chartBackgrounds: [
        "#7edd37"
      ],
      scalesObject: {
        y: {
          ticks: {
            color: 'white',
            align: 'center',
            font: {
              lineHeight: 1
            }
          },
        },
        x: {
          ticks: {
            align: 'start',
          },
          title: {
            display: true,
            text: '',
            font: {
              size: 14,
              style: 'normal',
              lineHeight: 1
            },
          }
        }
      }
    }
  },
  props: {
    from: String,
    to: String,
    chartLimit: Number,
    clickAllowed: Boolean
  },
  emits:   // optional
    ['clicked']
  ,
  created () {
    this.loadChartData('revenue')
    this.shownCurrency = this.getCurrency
    this.scalesObject.x.title.text = this.$t('dashboard.topRevenueWidget.salesInCurrency') + " " + this.getCurrency
    if (this.chartLimit === 100) {
      this.topType = 'top100'
    }
  },
  methods: {
    loadChartData() {
      this.loading = true
      DataService.getTopRevenueWidgetChart(this.from, this.to, this.chartLimit).then((response) => {
        this.responseChartData = response['data'].data
        this.refreshChart(this.revenueChartFilter)
        this.loading = false
      }).catch((err) => {
          // handle error
        this.loading = false
        console.log(err)
      })
    },
    transformDataToChart (dataObject) {
      var returnObject = dataObject
      if ( this.revenueChartFilter == 'bottles') 
      {
        returnObject.firstParam = dataObject.venueWineBottlesFloat
        returnObject.secondParam = dataObject.venueWineBottlesFloat

      } else if ( this.revenueChartFilter == 'glasses' ) {
        returnObject.firstParam = dataObject.venueWineGlasses
        returnObject.secondParam = dataObject.venueWineGlasses

      } else {
        returnObject.firstParam = dataObject.venueWineRevenueAvg
        returnObject.secondParam = dataObject.venueWineBottlesFloat

      }

      return returnObject
    },
    transformOuterDataToChart (dataObject) {
      var returnObject = dataObject
      returnObject.data = dataObject.map(data => this.transformDataToChart(data))
      return returnObject
    },
    refreshChart (filter) {
      this.revenueChartFilter = filter
      switch (filter) {
        case "bottles":
          this.chartTypeData = this.transformOuterDataToChart(this.responseChartData.bottleSort)
          this.scalesObject.x.title.text = this.$tc('general.bottle', 2) 
          this.shownCurrency = this.$tc('general.bottle', 2) 
          break;
        case "glasses":
          this.chartTypeData = this.transformOuterDataToChart(this.responseChartData.glassSort)
          this.scalesObject.x.title.text = this.$tc('general.glasses', 2) 
          this.shownCurrency = this.$tc('general.glasses', 2) 
          break;
        default:
          this.chartTypeData = this.transformOuterDataToChart(this.responseChartData.revenueSort)
          this.scalesObject.x.title.text = this.$t('dashboard.topRevenueWidget.salesInCurrency') + " " + this.getCurrency
          this.shownCurrency = this.getCurrency 
      }
    },
    sendMessageToParent () {
      this.$emit('clicked');
    },
  },
  computed: {
    getCurrency() {
      return getVenueCurrency(this.getVenue)
    },
    ...mapGetters(['getVenue'])
  },
  watch: {
    from: function () { 
      
        this.loadChartData()
      
    },
    to: function () {}
  }
};
</script>