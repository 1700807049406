import { equalsIgnoreCase } from './functions'

function applyMandatoryRatingAgencies(venueWineObject, ratingAgencies) {
  let mandatoryRatingAgencies = getMandatoryRatingAgencies(ratingAgencies)
  if ( venueWineObject.attributes.ratings == null) {
    venueWineObject.attributes.ratings = []
  }
  for (let a = 0; a < mandatoryRatingAgencies.length; a++) {
    const ratingAgencyId = mandatoryRatingAgencies[a].id;
    const defaultRatingValue = mandatoryRatingAgencies[a].default
    venueWineObject.attributes.ratings.push({
        value: defaultRatingValue,
        ratingAgency: ratingAgencyId
    })
  }
}

function getMandatoryRatingAgencies(ratingAgencies) {
  var mandatoryRatingAgencies = []
  ratingAgencies.data.forEach((ratingAgency) => {
              if(ratingAgency.attributes.mandatory === true) {
                  var mandatoryRatingAgency = {
                      id: ratingAgency.id,
                      default: ratingAgency.attributes.default,
                  }
                  mandatoryRatingAgencies.push(mandatoryRatingAgency)
              }
  })
  return mandatoryRatingAgencies
}

function  calcPurchasePriceAvg(purchasePrices) {
  var priceSum = 0.0;
  if (purchasePrices != null && purchasePrices.length > 0) {
    purchasePrices.forEach((element) => {
      priceSum += element.price;
    });
    priceSum = priceSum / purchasePrices.length;
    priceSum = Math.round(priceSum * 100) / 100;
  }
  return priceSum;
}

function addSupplierAndPrice(venueWine, price, supplier) {
  if (venueWine.attributes["purchasePrices"] == null) {
    venueWine.attributes["purchasePrices"] = []
  }
  const foundPurchasePrice = venueWine.attributes["purchasePrices"].find(
    (pp) => pp.price === price
  );
  if (!foundPurchasePrice) {
    var purchasePriceBody = {
      price: price,
      wineSupplier: supplier["id"],
    };
    venueWine.attributes["purchasePrices"] = []
    venueWine.attributes["purchasePrices"].push(purchasePriceBody);
  } else {
    foundPurchasePrice.wineSupplier = supplier["id"];
  }

  venueWine.attributes["purchasePriceAvg"] = calcPurchasePriceAvg(
    venueWine["purchasePrices"]
  )
  return venueWine
}

function createCSVArrayForExport(response){
  var csvArray = []
    response.forEach((venueWine) => {

      const category = venueWine.attributes.wine.data.attributes.category != null && venueWine.attributes.wine.data.attributes.category.data != null ? venueWine.attributes.wine.data.attributes.category.data.attributes.title : ''

      var row = {
        Weingut: venueWine.attributes.wine.data.attributes.winery.data.attributes.title,
        Weinname: venueWine.attributes.wine.data.attributes.title,
        Jahrgang: venueWine.attributes.year,
        Region: venueWine.attributes.wine.data.attributes.region.data.attributes.title,
        Land: venueWine.attributes.wine.data.attributes.land.data.attributes.title,
        Kategorie: category,
        Farbe: venueWine.attributes.wine.data.attributes.wineType.data.attributes.title,
        Rebsorte: venueWine.attributes.wine.data.attributes.grapeVariety.data.attributes.title,
        Anzahl: venueWine.attributes.amountFloat,
        EK: venueWine.attributes.purchasePriceAvg,
        VK: venueWine.attributes.sellingPrice,
        Flaschengröße: venueWine.attributes.bottleSize.data.attributes.amount,
        Verkostungsnotiz: venueWine.attributes.comments,
        Bezugsquelle: venueWine.attributes.purchasePrices && venueWine.attributes.purchasePrices.length > 0 ? venueWine.attributes.purchasePrices[0].wineSupplier.data.attributes.title : 'nicht vorhanden',
        Regal: venueWine.attributes.shelfNumber
      }
      csvArray.push(row)
    })
    return csvArray
}

function writeArrayToCSVDownload(array, fileName, papaParse) {
  var csv = papaParse.unparse(array);
  //var csv = this.$papa.unparse(array);
  //var venueName = this.getVenue.attributes.title + '_' + DateTimeService.dateToFileString(new Date())
  var csvData = new Blob([csv], {type: 'text/csv;charset=utf-8;'});
  var csvURL =  null;
  if (navigator.msSaveBlob)
  {
      csvURL = navigator.msSaveBlob(csvData, fileName + '.csv');
  }
  else
  {
      csvURL = window.URL.createObjectURL(csvData);
  }

  var tempLink = document.createElement('a');
  tempLink.href = csvURL;
  tempLink.setAttribute('download', fileName + '.csv');
  tempLink.click();
}

function getWineNameNullChecked(title, grapeVariety) {
  if (title == null || title == "" || title.trim() == "") {
    return grapeVariety
  }
  return title
}

function matchVenueWineWithEveryColumn(identifierKeys, offlineVenueWine, csvWine, identifierToVenueWineKey,identifierMappingObject, removeIdentifierFromMatichArrayCallBack){
  var isMatching = true;
  for (var i = 0; i <  identifierKeys.length; i++) {
    var identifierKey = identifierKeys[i];
    var venueWineIdentifier = identifierToVenueWineKey[identifierKey];
    var identifierCSVColumn = identifierMappingObject[identifierKey];
 
    var valueA = deepValueOfObject(offlineVenueWine, venueWineIdentifier);
    var valueB = csvWine[identifierCSVColumn];
    isMatching = matchValues(valueA, valueB)
    if (!isMatching){
      // console.log('-- NO match for ' + identifierKey + ' ' + stringA + ' ' + stringB);
      return false
    } else {

      removeIdentifierFromMatichArrayCallBack(identifierKey);
      // console.log('** match ' + identifierKey + ' ' + csvWine[identifierCSVColumn] + ' ' + this.deepValueOfObject(offlineVenueWine, venueWineIdentifier));
    }
  }
  return isMatching
}
function generateWineNameFromColumns(csvWine, identifierMappingObject){
  var wineName = ""
  var values = []
  for (var key in identifierMappingObject) {
    if (values.indexOf(csvWine[identifierMappingObject[key]]) > -1){
      continue
    }
    values.push(csvWine[identifierMappingObject[key]])
    wineName += csvWine[identifierMappingObject[key]] + " "
  }
  return wineName
}

function generateWineString(venueWine){
  var wineName = ""
  if (venueWine.attributes != null && venueWine.attributes.wine != null ) {
    wineName = venueWine.attributes.wine.data.attributes.winery.data.attributes.title + ", " + venueWine.attributes.title + ", " + venueWine.attributes.year + ", "+  venueWine.attributes.bottleSize.data.attributes.amount
  } else {
    wineName = venueWine.wine.winery.title + ", " + venueWine.title + ", " + venueWine.year + ", "+  venueWine.bottleSize.amount
  
  }
  return wineName
}

function matchValues(valueA, valueB) {
  var stringA = valueA;
  var stringB = valueB;
  
  if (Number.isInteger(valueA) && Number.isInteger(valueB)) {
    valueA = parseInt(valueA)
    valueB = parseInt(valueB)
    return valueA === valueB;
  } else if (valueA == 0 && valueB == 0) {
    return true
  } else if (valueA && valueB) {
    stringA = stringA.toString().toLowerCase().replace(",", ".").trim();
    stringB = stringB.toString().toLowerCase().replace(",", ".").trim();
    return equalsIgnoreCase(stringA,stringB);
  } else {
    return false
  }
}

function deepValueOfObject(object, path) {
  var pathArray = path.split('.');
  var value = object;
  pathArray.forEach((path) => {
    value = value[path];
  });
  return value;
}

export {
  applyMandatoryRatingAgencies,
  getMandatoryRatingAgencies,
  calcPurchasePriceAvg,
  addSupplierAndPrice,
  createCSVArrayForExport,
  writeArrayToCSVDownload,
  getWineNameNullChecked,
  matchVenueWineWithEveryColumn,
  deepValueOfObject,
  matchValues,
  generateWineNameFromColumns,
  generateWineString
}